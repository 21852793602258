$(function() {
    var $backTop = $('.backtop');

    if(!$backTop.length) return
    
    $backTop.on('click', function(e) {

        $('html, body').animate({
            scrollTop: $('body').offset().top
        }, 600);
    });
});


$(document).ready(function() {
    // Selecione o elemento com a classe .minha-classe
    var meuElemento = $('.login-remember label #rememberme');
    
    // Crie um novo elemento <span>
    var novoSpan = $('<span>');
    
    // Adicione o novo elemento <span> após o elemento selecionado
    meuElemento.after(novoSpan);
});