$(function () {
    $('.btn-toggle-menu').click(function (e) {

        $(this).toggleClass('active');

        $('.toggle-menu').slideToggle();
    });
});


$('.fl-cart_side-close').click(function (e) {
    $('.tdh_fl-cart').removeClass('tdh_fl-cart_open');
    $('body').css('overflow', 'auto');
});



$(function () {
    $('#customer_login .box-login').click(function (e) {

        $('.box-login .box-woocommerce').addClass('active');

    });
    $('#customer_login .box-register').click(function (e) {

        $('.box-register .box-woocommerce').addClass('active');

    });
});

$(document).ready(function () {
    if ($(window).width() < 1024) {
        var links = $('li.menu-item-has-children > a');
        var miniSubmenu = $('li.mini-submenu > a');
        var bigSubmenu = $('li.big-submenu > a');
        links.attr('href', 'javascript:;');

        miniSubmenu.on('click', function (e) {
            e.preventDefault();

            $('li.mini-submenu .sub-menu').toggleClass('sub-menu_open')
        });

        bigSubmenu.on('click', function (e) {
            e.preventDefault();

            $('li.big-submenu .sub-menu').toggleClass('sub-menu_open')
        });
    }
});
