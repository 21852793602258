jQuery(document).ready(function($){  
	$(window).on('load', function() {
		if (window.innerWidth > 1199 ) {

			$('.title-sec.left .title-sec_big').html(function(){	
				// separate the text by spaces
				var text= $(this).text().split(' ');
				// drop the last word and store it in a variable
				var first = text.shift();
				// join the text back and if it has more than 1 word add the span tag
				// to the last word
				return (text.length > 0 ? ' <span class="title-sec_big--first">'+first+'</span>' : first) + text.join(" ");   
			});
		}
	});
});

